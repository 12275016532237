import React from "react"

const FeaturedSitesIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.875 18.875L9 23L7.125 18.875L3 17L7.125 15.125L9 11L10.875 15.125L15 17L10.875 18.875Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2533 12.2533L18 15L16.7467 12.2533L14 11L16.7467 9.74667L18 7L19.2533 9.74667L22 11L19.2533 12.2533Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.94 5.94L11 8L10.06 5.94L8 5L10.06 4.06L11 2L11.94 4.06L14 5L11.94 5.94Z"
      fill="currentColor"
    />
  </svg>
)

export default FeaturedSitesIcon
